import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <img src={process.env.PUBLIC_URL + '/images/arachnalogo.jpg'} alt="Arachna logo" />
        <p className="subtitle-text">
          Coming Soon
        </p>
      </header>
    </div>
  );
}

export default App;
